module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-sentry/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://0029c0e1980d488eb702e79b1303a83e@o383121.ingest.sentry.io/5212982","environment":"production","enabled":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#78C8D7","showSpinner":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"typekit":{"id":"gfc7wqc","families":["brandon-grotesque"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Rob Gleeson | SD","short_name":"RobGleeson.dev","start_url":"/","background_color":"#ffffff","theme_color":"#e94e1b","display":"standalone","icon":"src/assets/images/favicon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"9536eafa56f88c9ca00dd1ef76c06f38"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
